.dashboard-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #0e347e;
}
.sms-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  &-count {
    background: #ffffff;
    border: 1px solid $dull-text;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 25px;
    &-total {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      color: #2f2e80;
      padding-bottom: 10px;
    }
    &-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $light-dark-color;
    }
  }
}

@media screen and (max-width: 768px) {
  .sms-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    &-count {
      width: 100%;
      height: 96px;
      padding: 25px;
      &-total {
        font-size: 15px;
      }
      &-title {
        font-size: 13px;
      }
    }
  }
}
