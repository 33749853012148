.sidebar,
.sidebar2 {
  position: fixed;
  top: 0;
  width: 248px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: "Inter";
  padding: 0px 16px;
  background: $deep-blue;
  z-index: 5;

  &-logo {
    height: 82px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &-section {
      margin-left: 20px;
      width: 130px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-item {
    cursor: pointer;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 18px;
    margin-bottom: 12px;

    &-icon {
      img {
        width: 100%;
        height: 100%;
        opacity: 0.7;
      }
    }

    &-icon2 {
      width: 13px;
      height: 11px;

      img {
        width: 100%;
        height: 100%;
        opacity: 0.7;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: $dull-text;
      margin-left: 14px;
    }
  }

  .grey {
    .sidebar-item,
    .sidebar2-item {
      width: 100%;
      height: 40px;
      background: rgba(139, 149, 171, 0.24);
      border-radius: 4px;
    }

    .sidebar-item-icon,
    .sidebar2-item-icon {
      img {
        opacity: 1;
      }
    }

    .sidebar-item,
    .sidebar2-item {
      span {
        color: #fff;
      }
    }
  }
}

.sidebar2 {
  display: none;

  &-left-item1,
  &-left-item2 {
    width: 35px;
    height: 3px;
    margin-bottom: 5px;
    background: $deep-blue;
  }

  .sidebar2-left {
    .close {
      position: relative;
      width: 30px;
      height: 30px;
    }

    .close:before,
    .close:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 3px;
      background-color: #fff;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker__input-container input {
  width: 100%;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: $deep-dark-color !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  letter-spacing: -0.05px !important;
  position: relative !important;
  border: 1px solid $dull-text;
  padding: 12px 16px !important;
  background-position: right 13px center !important;
  background-repeat: no-repeat !important;
  background-image: url("../../assets/calendar.svg") !important;

  &::placeholder {
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: -0.117188px !important;
    color: $light-dull-text !important;
  }

  &:focus {
    border: 1px solid $yellow-color !important;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
