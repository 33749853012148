.reporting-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #0d0f11;
}
.report-wrap {
  height: 100%;
  background: #ffffff;
  border: 1px solid $dull-text;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 20px 20px 20px;
}
.info-wrap {
  background: #ffffff;
  border: 1px solid $dull-text;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 0 15px 15px;
}
.report-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  padding: 25px 0;
  border-bottom: 1px solid $dull-text;

  &-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: $light-dark-color;
    padding-bottom: 10px;
  }

  &-info {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $light-dark-color;
  }

  &-logo {
    border-radius: 50px;
    margin-right: 10px;
  }
}

.report-details-title2 {
  margin-top: 20px;
}

.report-message {
  display: grid;
  // grid-template-columns: 1fr 1fr 2fr;
}

.report-detail-request {
  height: 190px;
  background: $dark-background;
  border-radius: 4px;
  overflow-y: scroll;
  padding: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $light-dark-color;

  pre {
    word-break: break-all;
    white-space: pre-wrap;
  }
}

.dropdown-wrap {
  position: relative;
  .table-dropdown {
    right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .reporting-title {
    margin-bottom: 10px;
  }

  .report-details {
    grid-template-columns: 1fr;
    &-title {
      display: none;
    }
  }
}
