.auth-wrap {
  width: 100%;
  align-items: center;
  display: flex;
  padding-top: 97px;
  flex-direction: column;
  justify-content: center;
}

.brand-logo {
  width: 155px;
  height: 35px;
  margin-bottom: 32px;

  img {
    width: 100%;
    height: 100%;
  }
}

.auth-form {
  width: 528px;
  min-height: 370px;
  padding: 42px 32px;
  box-sizing: border-box;
  background-color: #ffffff;
  justify-self: center;
  border: 1px solid $dull-text;
  border-radius: 4px;
  margin: 0 auto;
}

.page-headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  height: 48px;
  width: 100%;
  align-items: center;

  &-title {
    display: flex;
    align-items: center;
    height: 100%;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.25px;
      color: $normal-blue;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: $light-dull-text;
      margin-left: 13px;
    }
  }
  &-back {
    margin-right: 30px;
  }
}

.switch-wrapper {
  width: 100%;
  height: 100%;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 24px;

    &-link {
      min-width: 436px;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $dull-text;

      &-one,
      &-two {
        min-width: 55px;
        margin-right: 44px;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.002em;
        cursor: pointer;
      }

      &-one {
        color: $light-dull-text;
      }

      &-two {
        color: $light-blue;
        border-bottom: 2px solid $light-blue;
      }
    }

    &-right {
      display: flex;
    }
  }
}

@media (min-width: 500px) and (max-width: 1150px) {
  .auth-form {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100%;
  }

  .auth-wrap {
    padding: 20px;
    box-sizing: border-box;
    padding-top: 97px;
  }

  .page-headers {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
    grid-gap: 10px;
    height: fit-content;
    width: 100%;
  }

  .switch-wrapper {
    &-top {
      display: grid;
      justify-content: initial;

      &-right {
        display: grid;
        grid-gap: 10px;
        margin-top: 10px;
      }
    }
  }

  .switch-wrapper-top-link {
    min-width: 100% !important;
  }
}
