$deep-dark-color: #0D0F11;
$light-dark-color: #3A434B;
$dark-background: #F4F5F6;
$yellow-color: #FBD105;
$deep-blue: #162A56;
$dull-text: #D7DCE0;
$light-dull-text: #81909D;
$lato-text: #24292e;
$normal-blue: #0e347e;
$light-blue: #1354D3;
$search-text: #677684;
$error-color: #CA1B1B;
$error-color-dull: #fce9e9;
$info-color: #d66f0f;
$info-color-dull: #fdf2e7;
$success-color: #1E944D;
$success-color-dull: #eafbf1;
$table-border: #bac2c9;
$dull-yellow: #fff8e5;

