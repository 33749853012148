.sms-providers {
  &-right {
    display: flex;
  }
}

.ranking {
  width: 100%;
  height: 100%;

  &-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    min-height: 48px;
    border-bottom: 1px solid $dull-text;

    &-sec {
      display: block;
      width: 100%;
      height: 100%;
      margin-bottom: 24px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.002em;
        color: $light-dark-color;
        margin-bottom: 8px;
      }

      &-each {
        display: flex;
        align-items: center;

        &-image {
          width: 24px;
          height: 24px;
          background: $dull-yellow;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
          color: $light-dark-color;
        }

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: $light-dark-color;
          margin-left: 8px;
        }
      }
    }
  }

  &-lists {
    margin-top: 24px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: $deep-dark-color;
    }

    &-ranks {
      display: grid;

      &-single {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 92px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: $deep-dark-color;
        }

        &-box {
          height: 64px;
          width: 95%;
          background: $dark-background;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          padding: 16px;
          box-sizing: border-box;
          align-items: center;
          cursor: pointer;

          &-left {
            display: flex;
            align-items: center;

            &-image {
              width: 32px;
              height: 32px;
              background: $dull-yellow;
              box-sizing: border-box;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: $light-dark-color;
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: $light-dark-color;
              margin-left: 12px;
            }
          }

          &-right {
            width: 8px;
            height: 8px;
            background: #2c6eec;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.add-provider {
  .flex-steps {
    display: flex;
    justify-content: space-between;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: 0.2px;
      text-decoration-line: underline;
      color: $yellow-color;
      cursor: pointer;
    }
  }

  .provider-input-block {
    display: grid;
  }

  .steps {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #2f2e80;
    margin-bottom: 20px;
  }

  .provider-btn-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .add-icon {
    cursor: pointer;
    margin-top: 16px;
    height: 24px;
    width: fit-content;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: $yellow-color;
    border-bottom: 1px solid $yellow-color;

    span {
      margin-left: 8px;
    }
  }

  .add-provider-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
  }

  .add-provider-header-small {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $light-dark-color;
    margin-top: 5px;
    color: #81909d;
  }

  .response-div {
    margin-bottom: 24px;
  }

  .web-hook {
    margin-top: 16px;

    h2 {
      margin-bottom: 12px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $light-dull-text;
    }

    &-select {
      display: flex;
      align-items: center;
      height: fit-content;
      margin-bottom: 28px;
    
      &-btn {
        height: 20px;
        width: 20px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      span {
        margin-left: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #0b2253;
      }
    }
  }

  .configuration {
    margin-top: 32px;

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: $deep-dark-color;
      margin-bottom: 16px;
    }

    &-div {
      width: 100%;
      min-height: 40px;
      background: $dark-background;
      border: 1px solid $dull-text;
      box-sizing: border-box;
      border-radius: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      padding: 4px;
      grid-gap: 10px;

      &-box1,
      &-box2 {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
      }

      &-box1 {
        color: $light-dull-text;
      }

      &-box2 {
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        color: $deep-dark-color;
      }
    }
  }

  .request {
    margin-top: 32px;

    &-header {
      display: flex;
      align-items: center;
      min-height: 30px;

      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $light-dark-color;
        margin-bottom: 2px;
        margin-right: 13px;
      }
    }

    .textarea,
    .error-border {
      max-height: 128px;
      width: 100%;
      overflow-y: auto;
      background: #ffffff;
      border: 1px solid $dull-text;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $light-dark-color;
      display: flex;
      align-items: center;
    }

    .textarea:focus {
      border: 1px solid $yellow-color;
    }

    .error-border {
      border: 1px solid $error-color;
    }
  }

  .provider-example {
    width: 100%;

    &-header {
      margin-bottom: 20px;

      img {
        cursor: pointer;
      }

      span {
        margin-left: 18px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14.3203px;
        line-height: 16px;
        letter-spacing: 0.204575px;
        color: $light-dark-color;
      }
    }

    &-scroll {
      margin-top: 12px;
      margin-bottom: 44px;
      height: 156px;
      width: 100%;
      display: flex;
      overflow: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      &-box-wrap {
        display: inline-table;
      }

      &-box {
        background: $dark-background;
        border-radius: 4px;
        max-height: 156px;
        width: 422px;
        overflow-y: auto;
        padding: 12px 10px;
        box-sizing: border-box;
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $light-dark-color;
        margin-right: 20px;

        pre {
          width: 100%;
          height: 100%;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }
    }
  }
}

.toggle-button {
  margin-top: 24px;

  h2,
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $deep-dark-color;
  }

  &-body {
    display: flex;
    margin-top: 16px;
    align-items: center;
    min-height: 26px;

    span {
      margin-left: 16px;
    }
  }
}

.single-provider {
  &-button {
    background: $dull-yellow;
    border: 1px solid #ffd04d;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    min-width: 111px;
    padding: 20px;
    box-sizing: border-box;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #b38400;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: $dull-yellow;
      color: #b38400;
    }
  }

  &-wrap {
    height: 100%;
    min-height: 77vh;
    background: #ffffff;
    border: 1px solid $dull-text;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 24px 24px;

    &-details,
    &-details2,
    &-details3,
    &-details4 {
      display: grid;
      padding: 24px 0;
      border-bottom: 1px solid $dull-text;

      &-section {
        width: 100%;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.002em;
          color: $light-dark-color;
          margin-bottom: 10px;
        }

        span,
        h4,
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: $light-dark-color;
        }

        h4 {
          text-transform: capitalize;
        }

        .country {
          display: flex;
          align-items: center;
        }
      }
    }

    &-details {
      grid-template-columns: repeat(4, 1fr);
    }

    &-details2 {
      grid-template-columns: repeat(5, 1fr);
    }

    &-details3 {
      display: flex;
      border-bottom: none;
    }

    &-details4 {
      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #0d0f11;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background: $dull-text;
    }

    &-request {
      margin-bottom: 25px;

      &-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.002em;
        color: $light-dark-color;
        margin-bottom: 12px;
      }

      &-body,
      &-body2 {
        max-height: 190px;
        min-height: 128px;
        background: $dark-background;
        border-radius: 4px;
        overflow-y: auto;
        padding: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $light-dark-color;
        box-sizing: border-box;

        pre {
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      &-body2 {
        min-height: 40px;
      }

      &-body3 {
        min-height: 168px;
        background: #fff;
        border-radius: 4px;
        padding: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $light-dark-color;
        border: 1px solid #d7dce0;
        box-sizing: border-box;
        border-radius: 4px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-row-gap: 30px;

        .status-section {
          &-header {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.002em;
            color: $light-dark-color;
            text-transform: capitalize;
          }

          &-box {
            margin-top: 16px;
            display: flex;

            &-status {
              padding-right: 5px;
              border-right: 1px solid #3a434b;
              font-family: "Inter";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #3a434b;
              text-transform: capitalize;
              margin-right: 10px;
            }

            &-status:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .single-provider-grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid $dull-text;
  }
}

.single-provider-empty {
  margin-top: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: $light-dark-color;
  margin-bottom: 12px;
}

.provider-multitag {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;

  p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $light-dark-color;
  }

  small {
    margin-left: 5px;
    color: #81909d;
  }
}

.react-tagsinput {
  margin-top: 8px !important;
  padding: 12px 16px !important;
  background: #ffffff !important;
  border: 1px solid $dull-text !important;
  min-height: 48px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
}

.react-tagsinput--focused {
  border-color: $yellow-color !important;
}

.react-tagsinput-tag {
  width: fit-content !important;
  height: 24px !important;
  background: $dark-background !important;
  border-radius: 12px !important;
  border: none !important;
  display: inline-flex !important;
  align-items: center !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: $deep-dark-color !important;
  margin-bottom: 5px;
  margin-right: 8px !important;
  padding: 4px 8px !important;
}

.react-tagsinput-input {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  width: fit-content !important;
  background: transparent !important;
  box-sizing: border-box !important;
  outline: none !important;
  border: 0 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: $deep-dark-color !important;

  &::placeholder {
    font-size: 16px !important;
    line-height: 25px !important;
    letter-spacing: -0.05px !important;
    color: $deep-dark-color !important;
  }
}

.react-tagsinput-remove {
  cursor: pointer;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  margin-left: 10px !important;
}

.react-tagsinput-tag a::before {
  content: "×";
  color: $deep-dark-color;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .single-provider-wrap-details {
    grid-template-columns: auto;
    grid-row-gap: 20px;
  }

  .single-provider-wrap-details2 {
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
  }

  .single-provider .single-provider-grid2 {
    grid-template-columns: 1fr;
  }
}
