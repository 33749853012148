.button,
.button2 {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0 29px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 8px;
  color: $deep-dark-color;

  &:disabled {
    opacity: 0.32;
    cursor: not-allowed;
    color: $deep-dark-color;
  }

  &-data {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    span {
      margin-left: 8px;
    }

    a {
      text-decoration: none;
      color: $deep-dark-color;
    }
  }
}

.button {
  background: $yellow-color;
  color: $deep-dark-color;

  &:disabled {
    background: $yellow-color;
  }
}

.button2 {
  background: #fff;
  border: 1px solid $yellow-color;

  &:disabled {
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
}
