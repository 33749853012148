.requests-notifs {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;

  .showMoreBtn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.002em;
    color: $light-dark-color;
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 600;
    cursor: pointer;
  }

  &-single {
    min-height: 60px;
    width: 100%;
    display: flex;
    padding: 12px 0;
    box-sizing: border-box;
    border-bottom: 1px solid $dull-text;

    &-left {
      width: 10%;

      &-image {
        width: 32px;
        height: 32px;
        background: $dull-yellow;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: $light-dark-color;
      }
    }

    &-right {
      width: 85%;
      min-height: 80px;

      .action {
        min-height: 30px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.002em;
        color: $light-dark-color;
        margin-top: 2px;
        text-transform: lowercase;

        span {
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      h4 {
        margin: 8px 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $light-dull-text;
      }

      .buttons {
        width: 100%;
        display: flex;
        height: 24px;

        .decline,
        .approve {
          height: 100%;
          box-sizing: border-box;
          border-radius: 4px;
          font-family: "Inter";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
        }

        .approve {
          width: 147px;
          color: #fff;
          background: #27be63;
          border: none;
        }

        .decline {
          width: 126px;
          border: 1px solid $table-border;
          background: #fff;
          color: $light-dark-color;
          margin-right: 12px;
        }
      }
    }

    &-pointer {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $info-color;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .requests-notifs-single-left{
    width: 15%;
  }
}
