.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &-text {
    margin-right: 11px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $deep-dark-color;
  }
}

.loader-table {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}

.centre-loader {
  display: flex;
  justify-content: center;
}

.react-switch-handle {
  height: 16px;
  width: 16px;
  background: rgb(255, 255, 255);
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transform: translateX(17px);
  top: 2px;
  outline: 0px;
  border: 0px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
}

.invite-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-image {
    width: 300px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    margin-top: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #1d2029;
  }

  &-image2 {
    width: 156px;
    height: 156px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-status1,
  &-status2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 42px;
  }

  &-status1 {
    color: #27be63;
  }

  &-status2 {
    color: $error-color;
  }

  &-message {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin-top: 42px;
    text-align: center;
    color: $light-dark-color;
    margin-top: 24px;
  }

  &-button {
    width: 100%;
  }

  &-resend,
  &-resend a {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
    letter-spacing: 0.2px;
    color: #0e347e;
    text-decoration: underline;
    cursor: pointer;
  }
}
