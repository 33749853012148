.chart-wrap {
  width: 100%;
  min-height: 518px;
  background: #ffffff;
  border: 1px solid $dull-text;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  margin-top: 25px;

  .chartjs-render-monitor {
    display: block;
    height: 365px !important;
    width: 100%;
    margin-top: 30px;
  }

  .chart-top {
    display: grid;
    grid-template-columns: 3fr 1fr;
    &-duration {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      text-align: start;
      letter-spacing: 0.4px;
      color: $light-dark-color;
    }
    &-left {
      display: grid;
      grid-template-columns: 1fr 3.5fr;
      align-items: baseline;

      .lengend-box {
        display: flex;
        align-items: center;
      }

      .lengend {
        display: flex;
        align-items: center;
        margin-left: 25px;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $light-dull-text;
        }
      }
    }

    .dash {
      border: 2px solid #27be63;
      width: 16px;
      height: 0;
      margin-right: 5px;
    }

    .dash2 {
      border: 2px solid #ea6262;
    }
    &-right {
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      align-items: center;
    }
  }
}

.chart-wrap2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-top: 20px;
  &-box {
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin-top: 30px;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.25px;
    color: $deep-dark-color;
    margin-top: -15px;
    padding-bottom: 10px;
  }
  &-title2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: $light-dull-text;
    margin-bottom: 20px;
    span {
      font-weight: 600;
      color: $deep-dark-color;
    }
  }
  .sms-details,
  .sms-details2 {
    display: grid;
    width: 100%;
    height: 299px;
    background: #ffffff;
    border: 1px solid $dull-text;
    box-sizing: border-box;
    border-radius: 8px;
    .title-wrap {
      display: grid;
      align-self: center;
      padding-left: 20px;
      .top {
        margin-top: 20px;
      }
    }

    &-bar {
      display: grid;
      grid-template-columns: 1.5fr 2fr 1fr;
      border-top: 1px solid $dull-text;
      justify-items: unset;
      align-items: center;
      padding: 0 15px;
      grid-column-gap: 20px;
    }
    &-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: $light-dark-color;
    }
    &-count {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.donut-box {
  position: relative;
  margin-top: -15px;
  &-label {
    position: absolute;
    top: 80px;
    left: 103px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    color: $deep-dark-color;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $deep-dark-color;
    }
  }
  .chartjs-render-monitor {
    display: block;
    height: 195px !important;
    width: 295px !important;
  }
}
.sms-percent {
  margin-top: 17px;
  display: grid;
  grid-row-gap: 5px;
}

.provider-list-box {
  ul {
    text-align: justify;
    padding: 0;
  }
}
.provider-list {
  margin-left: -20px;
  display: grid;
  grid-row-gap: 20px;
  .provider-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .provider-name {
      display: flex;
      align-items: center;
      justify-content: right;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $light-dark-color;
      .dot {
        height: 14px;
        width: 14px;
        border: 1px solid gray;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .provider-count {
      display: flex;
      justify-content: space-evenly;
      .count {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.002em;
        color: $light-dark-color;
        margin-right: -27px;
      }
      .percent {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.002em;
        color: $light-dull-text;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .chart-wrap2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 20px;
    margin-top: 20px;
    &-box {
      grid-template-columns: 1.4fr 1.5fr;
    }
    &-title2 {
      margin-top: 10px;
    }

    .sms-details {
      .provider-list {
        display: grid;
        grid-row-gap: 10px;
        .provider-count {
          display: flex;
          justify-content: space-around;
          .count {
            margin-right: -10px;
          }
        }
        .provider-item {
          display: grid;
          grid-template-columns: 1fr 0.8fr;
        }
        .provider-name {
          font-size: 11.5px;
        }
      }
      &-bar {
        display: grid;
        grid-template-columns: 1fr;
        border-top: 1px solid $dull-text;
        justify-items: unset;
        align-items: center;
        padding: 0 15px;
        grid-column-gap: 20px;
      }
    }
  }
  .sms-details2 {
    display: grid;
    height: 100%;
    .chart-wrap2-box {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }
  .donut-box {
    &-label {
      position: absolute;
      top: 65px;
      left: 60px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: $deep-dark-color;
      span {
        font-size: 10px;
      }
    }
    .chartjs-render-monitor {
      display: block;
      height: 165px !important;
      width: 195px !important;
    }
  }

  .chart-wrap {
    width: 100%;
    min-height: 518px;
    background: #ffffff;
    border: 1px solid $dull-text;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    margin-top: 25px;

    .chartjs-render-monitor {
      display: block;
      height: 365px !important;
      width: 100%;
      margin-top: 30px;
    }
    .chart-top {
      display: grid;
      grid-template-columns: 1fr;
      &-duration {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        text-align: start;
        letter-spacing: 0.4px;

        color: $light-dark-color;
      }
      &-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: baseline;

        .lengend-box {
          display: grid;
          grid-row-gap: 8px;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}
