.verification-wrap {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  font-family: "Inter";

  &-input {
    width: 48px;
    height: 48px;
    text-align: center;
    background: #ffffff;
    border: 1px solid $dull-text;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 20px;
  }
}

.verification-confirmation {
  max-width: 100%;
  height: -moz-fit-content;
  margin-top: 50px;
  text-align: center;
  color: $light-dark-color;
  font-size: 14px;
  font-family: "Inter";

  &-resend {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
    color: $yellow-color;
    padding-left: 12px;
    cursor: pointer;
  }
}
.verification-time {
  font-family: "Inter";
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $light-dark-color;
}
.time-info {
  color: $info-color;
}

.check-email {
  height: 161px;
  img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .verification-wrap {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    justify-content: center;
    margin: 40px 0;
    font-family: "Inter";

    &-input {
      width: 100%;
      height: 48px;
      text-align: center;
      background: #ffffff;
      border: 1px solid $dull-text;
      box-sizing: border-box;
      border-radius: 4px;
      // margin-right: 20px;
    }
  }
}
