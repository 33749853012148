body {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: $dark-background;
}

.container2 {
  width: 100%;
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
}

.container-body {
  background: #ffffff;
  position: relative;
  top: 64px;
  margin-left: 248px;
  padding: 16px 32px;
  padding-bottom: 50px;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    z-index: 1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $dark-background;
    z-index: 1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $light-dull-text;
    border-radius: 2px;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .container-body {
    background: #ffffff;
    position: relative;
    top: 64px;
    margin-left: 0px;
    padding: 16px 20px;
  }
}
