.modal,
.modal2,
.requests-modal {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 1;
  right: 0;
}

.modal,
.modal2 {
  top: 0;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.requests-modal {
  top: 60px;
}

.modal-content,
.requests-modal-content {
  background-color: #fff;
  max-width: 574px;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
  float: right;
  right: 0;
  overflow: auto;
  margin-bottom: 50px;

  &-header {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    box-sizing: border-box;
    border-bottom: 1px solid $dull-text;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: $deep-dark-color;
    }
  }

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    box-sizing: border-box;

    &-form {
      width: 100%;
    }
  }

  &-container2 {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    padding: 24px 55px;
    box-sizing: border-box;
  }
}

.requests-modal-content {
  border: 1px solid #d7dce0;
  box-shadow: 0px 10px 20px rgba(171, 180, 189, 0.4);

  &-container {
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 20px;

    &-form {
      width: 100%;
    }
  }
}

// Delete Modal
.modal2 {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal2-content {
    background-color: #fff;
    max-width: 400px;
    height: 232px;
    padding: 16px 24px;
    padding-bottom: 32px;
    margin: auto;
    box-sizing: border-box;

    &-header {
      height: 64px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid $dull-text;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $deep-dark-color;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      &-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.048px;
        color: $light-dark-color;
        font-weight: 400;

        span {
          font-weight: 600;
        }
      }

      &-button {
        display: flex;
        height: 40px;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;

        span {
          cursor: pointer;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: $light-dark-color;
          margin-right: 20px;
        }

        button {
          font-family: "Inter", sans-serif;
          box-sizing: border-box;
          padding: 0 29px;
          border: none;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          border-radius: 4px;
          background: $error-color;
          color: #fff;
          width: 134px;
          height: 40px;

          &:disabled {
            opacity: 0.32;
            cursor: not-allowed;
            background: $error-color;
            color: #fff;
          }
        }
      }
    }
  }
}

.close-modal {
  color: $light-dark-color;
  float: right;
  font-size: 25px;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content, .modal2 .modal2-content, .requests-modal-content {
    max-width: 100% !important;
    width: 100% !important;
  }
}
