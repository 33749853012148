.input-wrapper {
  .input-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .label {
      font-size: 14px;
      line-height: 16px;
      color: $light-dark-color;
      letter-spacing: 0.4px;
      background: white;
      margin-bottom: 8px;
    }

    .input,
    .error-border {
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      color: $deep-dark-color;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.05px;
      position: relative;
    }

    .input {
      border: 1px solid $dull-text;
    }

    .input::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.117188px;
      color: $light-dull-text;
    }

    .input:focus,
    textarea:focus {
      border: 1px solid $yellow-color;
    }

    .error-border {
      border: 1px solid $error-color;
    }

    .readonly-input {
      background: $dark-background;

      &:focus {
        border: 1px solid $dull-text;
      }
    }
  }

  .showhide-btn {
    position: absolute;
    color: $yellow-color;
    font-size: 16px;
    cursor: pointer;
    right: 16px;
    bottom: 16px;
    font-family: "Inter";
  }
}

.form-grid {
  margin-top: 24px;
}

.form-grid2,
.form-grid3 {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.form-grid3 {
  margin-top: 20px;
}

.errors {
  display: block;
  margin-top: 5px;
  font-size: 1.4rem;
  color: $error-color;
  font-weight: 400;
  transition: all 300ms ease-in-out;
}

.errors::first-letter {
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  cursor: pointer;
  color: $deep-blue !important;
  display: block;
  padding: 8px 10px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.Dropdown-option:hover {
  background: $dark-background !important;
}

.Dropdown-menu {
  background-color: white;
  box-sizing: border-box;
  margin-top: 4px !important;
  max-height: 600px !important;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border: 1px solid $dull-text !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px !important;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid $dull-text !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  outline: none;
  padding: 12px 16px !important;
  transition: all 200ms ease;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.117188px;
  color: $deep-dark-color !important;
  height: 48px !important;
}

.Dropdown-control:hover {
  background: none !important;
}

.Dropdown-arrow {
  content: "";
  display: inline-block !important;
  position: absolute;
  right: 10px;
  top: 16px !important;
  border-width: 0 !important;
  width: 0.4em !important;
  height: 0.4em !important;
  border-right: 0.2em solid $light-dark-color !important;
  border-top: 0.2em solid $light-dark-color !important;
  transform: rotate(130deg) !important;
  margin-right: 0.5em !important;
  cursor: pointer;
}

.Dropdown-placeholder {
  color: #ccc !important;
}

.is-open .Dropdown-arrow {
  border-color: none !important;
  transform: rotate(-45deg) !important;
  top: 20px !important;
}

.is-selected {
  background: none !important;
  color: $deep-dark-color !important;
}

.country-currency {
  height: 48px;
  padding: 16px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid $dull-text;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;

  .divider {
    height: 32px;
    width: 1px;
    background: #9da9b3;
  }

  &-code {
    width: 30%;
  }

  &-value input {
    width: 60%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.05px;
    border: none;
    margin-left: 16px;
  }
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid $dull-text !important;
  font-family: "Inter" !important;

  &::placeholder {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: -0.117188px !important;
    color: $light-dull-text !important;
  }
}

.infinite-limit {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.117188px;
  color: #81909d;
}

@media screen and (max-width: 768px) {
  .form-grid2,
  .form-grid3 {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
